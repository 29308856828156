/**
 * @description 性能监控
 * @module entry
 */

import { PerfSuitePointEnum } from './constant';
import { get } from 'lodash-es';

// 校验是否执行
let ifInit = false;

/**
 * 初始化 PerformanceObserver
 */
function initObserver(): void {
  if (ifInit) return;
  ifInit = true;
  /**
   * 监听 performance.measure
   */
  const perfMeasureObserver = new PerformanceObserver((list) => {
    list.getEntries()
      .forEach((entry) => {
      // Process "mark" and "measure" events
        const event = entry.toJSON();
        const eventName = event.name;
        if (typeof eventName !== 'string') return;
        if (!eventName.startsWith('Suite')) return;

        window.aegisSDK?.reportTime({
          name: eventName,
          duration: event.duration,
        });
        window.aegisV2?.reportEvent({
          name: eventName,
          duration: event.duration,
          product_name: location.pathname.split('/')[1],
          analytics_session_id: get(window.aegisV2, 'bean.sessionId') as string,
        });
      });
  });

  /**
   * 监听耗时计算方法 performance.measure
   * buffered 表示会触发监听之前的条目
   * @doc https://developer.mozilla.org/en-US/docs/Web/API/PerformanceObserver/observe
   */
  perfMeasureObserver.observe({
    type: 'measure',
    buffered: true,
  });

  /**
   * 监听性能导航时序
   * @doc https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming
   */
  const perfNavObserver = new PerformanceObserver((list) => {
    list.getEntries()
      .forEach((entry) => {
        window.aegisSDK?.reportEvent({
          name: PerfSuitePointEnum.NavigationTiming,
          ext1: JSON.stringify(entry.toJSON()),
        });
        window.aegisV2?.reportEvent({
          name: PerfSuitePointEnum.NavigationTiming,
          ext1: JSON.stringify(entry.toJSON()),
          analytics_session_id: get(window.aegisV2, 'bean.sessionId') as string,
        });
      });
  });

  /**
   * 监听耗时计算方法 performance.measure
   * buffered 表示会触发监听之前的条目
   * @doc https://developer.mozilla.org/en-US/docs/Web/API/PerformanceObserver/observe
   */
  perfNavObserver.observe({
    type: 'navigation',
    buffered: true,
  });
}

initObserver();
