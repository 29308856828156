/**
 * @description 组织架构相关声明，org 为组织结构缩写
 * @module types
 */

import type { AppState, BEAppKeyType, EnabledBusi } from '@/constants/micro';
import type { PageReq } from './base';

export enum OrgLevelTypes {
  ROOT_ORG_LEVEL = 'root',
  CORP_ORG_LEVEL = 'corp',
  BUSINESS_ORG_LEVEL = 'business',
  BIZ_ORG_LEVEL = 'biz',
  TEAM_ORG_LEVEL = 'team',
}

// 组织结构层级
export type OrgLevelType = 'root'|'corp'|'business'|'biz'|'team';

export const ORG_LEVELES: OrgLevelType[] = [
  'root',
  'corp',
  'business',
  'biz',
  'team',
];

export interface OrgReq {
  appKey: BEAppKeyType
  id: string
  level: OrgLevelType
  childLevel: OrgLevelType | null
  pageNo: number
  pageSize: number
  // 是否展示无权限的空间
  showNoPermission?: boolean
}

// 组织结构单元信息
export interface OrgUint {
  // 组织单元 ID，
  id: string
  // 当前层级
  level: OrgLevelType
  // 名称
  name: string
  // 是否为空间管理员，仅 level 为
  isAdmin?: boolean
  // 应用映射的 id，TAB 的业务id映射到灯塔的 bizId
  // 当前只有 TAB 及 TX 使用该 ID，其它应用下 appOrgId 等于 id
  appOrgId?: string
}

// 最近访问的组织单元
export interface RecentOrgUnit extends OrgUint {
  childLevel: OrgLevelType | null
}

// 访问的空间
export interface VisitBizReq {
  appKey: BEAppKeyType;
  bizId: string;
  teamId?: string;
  teamName?: string;
}

// 定位当前组织架构
export interface LocatedOrgReq {
  appKey: BEAppKeyType;
  // 如果当前应用组织架构最后一级为 biz 时， 传 ${bizId}
  // 对于 DH 需要传 ${teamId}
  id: string;
  // level 传最后一级组织架构 biz，对于 DH 需要传 team
  level: OrgLevelType;
  // 如果当前应用组织架构最后一级为 biz 时，不需要传
  // 对于 DH 需要传 biz
  childLevel?: OrgLevelType;
  // 如果当前应用组织架构最后一级为 biz 时，不需要传
  // 对于 DH 需要传 ${bizId}
  childId?: string;
}

// 查询组织信息请求
export interface SearchOrgsReq extends PageReq {
  appKey: BEAppKeyType;
  corporationId: string;
  keyword: string;
  showNoPermission: boolean;
}

// 企业租户信息
export interface CorporationInfo {
  // 准许加入企业的邮箱后缀
  allowEmailSuffixes: string;
  // 开通应用标示
  appKeys: BEAppKeyType[];
  // 联系人
  contact: string;
  // 租户/集团/企业id
  corporationId: string;
  // 租户/集团/企业名称
  corporationName: string;
  // 租户/集团/企业简称
  corporationShort: string;
  // 创建时间
  createTime: string;
  // 创建人邮箱
  creatorEmail: string;
  // 创建人用户名
  creatorName: string;
  // 创建人团队角色
  creatorRole?: number;
  // 联系邮箱
  email: string;
  // 是否开启业务管理，1是，0否
  enabledBusi: EnabledBusi;
  // 一级行业
  first: string;
  // 所属行业 ID
  industryId?: number;
  // logo
  logo: string;
  // 联系电话
  phone: string;
  // 企业规模
  scale?: number;
  // 二级行业
  second?: string;
  // 是否是当前企业管理员
  corpAdmin: boolean;
}

// 应用信息
export interface AppInfo {
  appKey: BEAppKeyType;
  // 应用描述
  appName: string;
  // 开通状态
  expiredState: AppState;
  // 应用名称
  showAppName: string;
}

export interface BusinessReq extends PageReq {
  corporationId: string;
}

// 业务信息
export interface BusinessInfo {
  // 业务英文标识
  busiCode: string;
  // 业务唯一ID
  busiId: string;
  // 业务名称
  busiName: string;
  // 企业租户ID
  corporationId: string;
  // 运营产品 ID
  obsProductId?: string;
  // 运营产品名
  obsProductName?: string;
}

// 创建空间参数
export interface CreateBizParams {
  appKeys: BEAppKeyType[];
  bizId: string;
  bizName: string;
  corporationId: string;
  appKey: string;
  busiId: string;
}

// 最近访问的空间请求体
export interface RecentVisitReq {
  corporationId: string,
  appKey: BEAppKeyType,
  // 当前空间ID，在 getSameBiz 为true时必传
  bizId?: string,
  getSameBiz?: boolean,
}

// 最近访问的空间请求体
export interface SwitchAppReq {
  corporationId: string,
  appKey: BEAppKeyType,
  // 当前空间ID
  bizId?: string,
  fromApp?: BEAppKeyType,
  // 上个空间所在的业务
  fromBusiId?: string,
}

// Demo空间请求体
export interface DemoBizReq {
  appKey: BEAppKeyType,
}

// 用户在当前空间下，各组织层级下是否是管理员
export interface UserOrgAdmin {
  // 是否为当前空间全局管理员
  bizGlobalAdmin: boolean;
  // 是否是空间管理员
  bizAdmin: boolean;
  // 是否为业务管理员
  busiAdmin: boolean;
}

// 获取空间信息返回体
export interface BizInfo {
  // 业务名称
  busiName: string,
  // 管理员名列表
  bizAdminListString?: string,
  // 空间 id
  id: string,
  // 空间名称
  name: string,
  // 企业 id
  corporationId: string,
  // 空间管理员
  adminList: {
    id: string,
    name: string,
  }[],
  // 全局管理员
  globalAdminList: {
    id: string,
    name: string,
  }[],
}

export interface OrgDetailInfo {
  corporation: CorporationInfo;
  consoleAppList: AppInfo[];
}
