/*
 * @Author: cocogong
 * @Date: 2024-06-07 11:10:22
 * @LastEditTime: 2024-08-08 15:30:08
 * @LastEditors: cocogong
 * @Description: ola-chat
 */

import { defineStore } from 'pinia';
import type { IPosition } from './typed';

export interface SmallCopilotConfig {
  visible?: boolean,
  pos?: IPosition,
  requestData?: object,
  curRequestId?: string | null,
}

export interface StopQuestionParams {
  requestId: string,
}

export interface SqlExceptionInfo {
  sql?: string,
  dataSourceType?: number,
  dataSourceName?: string,
  dataSourceId?: string | number,
  databaseName?: string,
  tdwDatabaseOwner?: string,
  exception?: {
    title: string,
    msg: string,
    traceId: string,
  },
}
export interface QueryResultInfo {
  columns?: string[],
  columnTypes?: string[],
  rows?: any[][],
  dataResource?: {
    id?: string | number,
    fieldList: string[],
  },
}

export interface AddQuestionParams {
  content?: string,
  contextConfig: {
    userGuidOrigin: number,
    intentionType: number,
    sqlExceptionInfo?: SqlExceptionInfo,
    queryResultInfo?: QueryResultInfo,
  }
}

export interface IToolbarItem {
  id?: string,
  name?: string;
  icon?: string;
  iconSize?: number;
  onClick?: (() => void) | null;
}

export interface IChatInput {
  pos?: IPosition,
  initStr?: string,
}

export interface IToolbarConfig {
  visible?: boolean,
  pos?: IPosition,
  toolbarList: IToolbarItem[],
}

export interface IDisabledIntentionItem {
  intentionType: number,
  tipText: string,
}

export interface DataSourceInfo {
  dataSourceType?: number,
  dataSourceName?: string,
  dataSourceId?: string,
}

export interface OlaChatState {
  smallCopilotConfig: SmallCopilotConfig,
  // 提问
  copilotAddQuestionCb: ((params: AddQuestionParams) => void) | null;
  copilotStopQuestionCb: ((params: StopQuestionParams) => void) | null;
  copilotSetAppraiseStatusCb: (() => void) | null;
  copilotInitOlaChatCb: (() => void) | null;
  showRightOlaCopilot: boolean;
  intentionText: string;
  toolbarConfigList: IToolbarItem[],
  showToolbar: boolean,
  toolbarPos: IPosition,
  toolbarConfig: IToolbarConfig,
  chatInputConfig: IChatInput,
  curTargetPos: IPosition | null,
  disabledIntentionList?: IDisabledIntentionItem[],
  dataSourceInfo?: DataSourceInfo | undefined,
  isSqlMode: boolean,
}

export const useOlaChatStore = defineStore('olaChatStore', {
  state: (): OlaChatState => ({
    copilotAddQuestionCb: null,
    copilotStopQuestionCb: null,
    copilotSetAppraiseStatusCb: null,
    copilotInitOlaChatCb: null,
    smallCopilotConfig: {
      visible: false,
      pos: {},
      requestData: {},
      curRequestId: null,
    },
    showRightOlaCopilot: false,
    intentionText: '',
    toolbarConfigList: [],
    showToolbar: false,
    toolbarPos: {},
    chatInputConfig: {
      pos: {},
      initStr: '',
    },
    toolbarConfig: {
      visible: false,
      pos: {},
      toolbarList: [],
    },
    curTargetPos: null,
    disabledIntentionList: [],
    dataSourceInfo: undefined,
    isSqlMode: false,
  }),
  actions: {
    updateCopilotAddQuestionCb(callback: (() => void) | null): void {
      this.copilotAddQuestionCb = callback;
    },
    updateCopilotStopQuestionCb(callback: (() => void) | null): void {
      this.copilotStopQuestionCb = callback;
    },
    updateCopilotSetAppraiseStatusCb(callback: (() => void) | null): void {
      this.copilotSetAppraiseStatusCb = callback;
    },
    updateCopilotInitOlaChatCb(callback: (() => void) | null): void {
      this.copilotInitOlaChatCb = callback;
    },
    switchSmallCopilotShow(visible?: boolean): void {
      if (visible !== undefined) {
        this.smallCopilotConfig.visible = visible;
        return;
      }
      this.smallCopilotConfig.visible = !this.smallCopilotConfig.visible;
    },
    updateSmallCopilotConfig(config: SmallCopilotConfig) {
      this.smallCopilotConfig = Object.assign({}, this.smallCopilotConfig, config);
    },
    switchRightCopilotShow(visible?: boolean): void {
      if (visible !== undefined) {
        this.showRightOlaCopilot = visible;
        return;
      }
      this.showRightOlaCopilot = !this.showRightOlaCopilot;
    },
    updateIntentionText(text: string) {
      this.intentionText = text;
    },
    updateChatInputConfig(config: IChatInput) {
      this.chatInputConfig = Object.assign({}, this.chatInputConfig, config);
    },
    updateToolbarConfig(config: IToolbarConfig) {
      this.toolbarConfig = Object.assign({}, this.toolbarConfig, config);
    },
    updateCurTargetPos(pos: IPosition | null) {
      this.curTargetPos = pos;
    },
    setDisabledIntentionList(disabledIntentionList?: IDisabledIntentionItem[]) {
      this.disabledIntentionList = disabledIntentionList;
    },
    setDataSourceInfo(dataSourceInfo?: DataSourceInfo) {
      this.dataSourceInfo = dataSourceInfo;
    },
    setSqlModeStatus(status: boolean) {
      this.isSqlMode = status;
    },
    getOlaChatServerHost() {
      return window.FrontConfig.olaChatConfig?.olaMicroAppServiceHost;
    }
  },
});
