/**
 * @description 微前端状态 Store
 * @module store
 */
import type { NavigationFailure } from 'vue-router';
import { defineStore } from 'pinia';
import { get } from 'lodash-es';
import globalAPI from '@/services/global-api';
import $router from '@/router';
import { AppKeyMapping } from '@/constants/micro';
import { ORG_LEVELES, RecentOrgUnit } from '@/types/apis/organisation';
import { fetchLoginTicket } from '@/services/apis/user';
import { appendParamsUrl } from '@/utils/append-params-url';
import { setCorsCookie } from '@/utils/set-cors-cookie';
import { isMobile } from '@/utils/device-detect';
import { useRightSidebarStore } from '@/stores/right-sidebar';

export interface MicroAppStoreState {
  // 当前子应用
  currentAppId: MicroApp.AppKeyType;
  // 即将进入的子应用名
  comingAppId: MicroApp.AppKeyType | '';
  // 子应用列表
  allApps: MicroApp.AppOpts[];
  // 子应用 map
  appMap: Partial<Record<MicroApp.AppKeyType, MicroApp.AppOpts>>
  // 是否展示 404 页面
  visibleOfPageNotFoundPage: boolean;
}

export const useMicroAppStore = defineStore('microapp', {
  state: (): MicroAppStoreState => ({
    currentAppId: 'console',
    comingAppId: '',
    allApps: [],
    appMap: {},
    visibleOfPageNotFoundPage: false,
  }),
  getters: {
    currentAppIdBE: state => AppKeyMapping[state.currentAppId],
    comingAppIdBE: state => (state.comingAppId ? AppKeyMapping[state.comingAppId] : ''),
    currentApp: state => state.allApps.find(app => app.id === state.currentAppId),
    ifEnableUserFeedback: (state) => {
      // 移动端关闭入口
      if (isMobile()) return false;
      // 启用的用户反馈入口应用
      const enabledFBAppKeys = get(window, 'FrontConfig.userFeedBack.enabledAppKeys', []);
      return enabledFBAppKeys.includes(state.currentAppId);
    },
  },
  actions: {
    /**
     * 初始化微前端 App 配置，在 index.html 调用的后端接口返回的数据，在 Vue 实例化时设置
     */
    initMicroAppConfigs(apps: MicroApp.AppOpts[]) {
      this.allApps = apps;
      this.allApps.forEach((app) => {
        this.appMap[app.id] = app;
      });
    },
    initRightSidebar(appKey: MicroApp.AppKeyType) {
      // FIXME: 初始化前重置 store 后面考虑和 navigation 收敛到一起
      const rightSidebarStore = useRightSidebarStore();
      const oldExpandVal = rightSidebarStore.isExpand;
      rightSidebarStore.$reset();
      const config = get(this.appMap[appKey], 'rightSidebar', {});
      rightSidebarStore.enableSideBar = get(config, 'enableSideBar', false);
      rightSidebarStore.menuList = get(config, 'menuList', []);
      // 侧边栏开启的情况 默认保留上次的 oldExpandVal
      if (rightSidebarStore.enableSideBar) {
        rightSidebarStore.isExpand = oldExpandVal;
      }
    },
    /**
     * 进入子应用
     * @param appKey {AppKeyType} 子应用名
     */
    async enterSubApp(appKey: MicroApp.AppKeyType) {
      if (!this.appMap[appKey]) return;
      this.currentAppId = appKey;
      this.initRightSidebar(appKey);
      if (!this.currentApp?.loginTicket || !this.currentApp?.url) return;
      // 处理 请求子应用 index.html 需要添加 loginTicket 的情况，例如 saas wuji
      const loginTicket = await fetchLoginTicket();
      const url = appendParamsUrl(this.currentApp.url, {
        name: 'login_ticket',
        value: loginTicket,
        override: true,
      });
      await setCorsCookie(url);
    },
    /**
     * 设置即将激活的子应用
     * @param appKey {AppKeyType} 子应用名
     */
    setComingSubApp(appKey: MicroApp.AppKeyType | '') {
      this.comingAppId = appKey;
    },
    /**
     * 获取子应用信息
     * @param appKey
     */
    getAppConfig(appKey: MicroApp.AppKeyType): MicroApp.AppOpts | undefined {
      return this.allApps.find(app => app.id === appKey);
    },
    /**
     * 构建 URL 通过组织架构
     * @param url
     * @param belongOrgs
     * @returns
     */
    buildOrgsUrl(url: string, belongOrgs: RecentOrgUnit[]) {
      let finallyLandUrl = url;
      belongOrgs.forEach((orgLevel) => {
        finallyLandUrl = finallyLandUrl.replace(`\${${orgLevel.level}}`, orgLevel.appOrgId || orgLevel.id);
      });
      return finallyLandUrl;
    },
    /**
     * 构造应用落地页 URL
     * @param appName {MicroApp.AppKeyType}
     * @param belongOrgs {RecentOrgUnit[]}
     * @returns {string | undefined}
     */
    buildLandPageUrl(appName: MicroApp.AppKeyType, belongOrgs?: RecentOrgUnit[]| null): string {
      const currentApp = this.allApps.find(app => app.id === appName);
      if (!currentApp || !currentApp.landPageUrl) return '';
      const { landPageUrl } = currentApp;
      // 落地页路径是否包含组织信息
      const hasOrg = ORG_LEVELES.some(level => new RegExp(`\\$\{${level}}`).test(landPageUrl));
      // 不包含组织信息的路径直接返回
      if (!hasOrg) return landPageUrl;
      // 包含组织信息的路径且必须存在组织信息
      if (belongOrgs?.length) {
        return this.buildOrgsUrl(landPageUrl, belongOrgs);
      }
      return '';
    },
    /**
     * 进入当前子应用落地页
     * @param belongOrgs {RecentOrgUnit[]}
     */
    enterCurrentAppLandPage(belongOrgs: RecentOrgUnit[]) {
      const landPageUrl = this.buildLandPageUrl(this.currentAppId, belongOrgs);
      globalAPI.router.routerPush({ path: landPageUrl, eventName: 'OrgnisationChange' });
    },
    /**
     * 获取子应用不包含的公共模块
     * @param appKey
     * @returns
     */
    getAppExcludesModuleKeys(appKey: MicroApp.AppKeyType): MicroApp.AppKeyType[] {
      const currentAppConfig = this.getAppConfig(appKey);
      return get(currentAppConfig, 'sidebar.excludesModule', []);
    },
    /**
     * 在主应用内进入到子应用
     * @param appKey
     * @param url
     * @returns
     */
    routerToApp(url: string): Promise<NavigationFailure | void | undefined> {
      if (!$router.instance) return Promise.resolve();
      // 是否进入子应用时刷新页面，离开需要刷新页面的子应用
      // 用于子应用内嵌套子应用修改错误的 history 导致 bug 的问题
      if (this.currentApp?.entryRefresh) {
        const resolveUrl = $router.instance.resolve(url);
        window.location.href = resolveUrl.fullPath;
        return Promise.resolve();
      }
      return $router.instance.push(url);
    },
    /**
     * 唤起或关闭主应用404页面
     * @param visible boolean
     * @returns
     */
    setPageNotFoundVisible(visible: boolean): void {
      this.visibleOfPageNotFoundPage = visible;
    },
  },
});
