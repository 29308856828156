import { PATCH_NO_NEED_VERIFY_USER } from '@version-inject/constants/route';

/**
 * @description 路由相关常量定义
 * @module types
 */

// 路由名称
export enum RouteNames {
  HomePage = 'HomePage',
  SpaceSwitch = 'SpaceSwitch'
}

// 不需要登录鉴权的路径正则
export const NO_NEED_VERIFY_USER: RegExp[] = [
  // dataauth的微信登陆页
  /^(\/dataauth\/wxlogin)$/,
  // dataauth的注册页面
  /^(\/dataauth\/registerBeacon)$/,
  // 云上未订购页
  /^(\/console\/not-purchased)/,
  // 云上登陆页面
  /^\/tencent-cloud-login/,
  // dataauth的登陆页
  /^(\/dataauth\/login)/,
  // dataauth的注册页
  /^(\/dataauth\/register)/,
  // dataauth的租户邀请页
  /^(\/dataauth\/invite)/,
  ...PATCH_NO_NEED_VERIFY_USER,
];

// 不需要校验企业信息
export const NO_NEED_VERIFY_CORP: RegExp[] = [
  // 企业选择页
  /\/dataauth\/groupList/,
  /\/dataauth\/company\/list/,
  // 云上未订购页
  /^(\/console\/not-purchased)$/,
  // 云上登陆页面
  /^\/tencent-cloud-login/,
];
