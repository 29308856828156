/*
 * @Author: cocogong
 * @Date: 2024-06-03 16:58:31
 * @LastEditTime: 2024-08-12 20:09:34
 * @LastEditors: cocogong
 * @Description: olaChat模块相关的通信
 */
import {
  useOlaChatStore,
  type IToolbarConfig,
  type IChatInput,
  type SmallCopilotConfig,
  type AddQuestionParams,
  type IDisabledIntentionItem,
  type DataSourceInfo,
} from '@/stores/ola-chat';
import type {
  EventCenterObserverCallback,
} from '../index.types';
import eventCenter from '../event/eventCenter';
import { DEFAULT_RIGHT_COPILOT_WIDTH } from '@/components/ola-chat/util';

enum OlaChatEventType {
  // 与 StoreID 对应
  Action = 'olaChat:action',
}

interface OlaChatModuleParams {
  text: string,
}

export interface IOlaChatAPI {
  switchRightCopilotShowStatus(visible: boolean | undefined): void,
  addOlaChatQuestion(params: AddQuestionParams): void,
  updateToolbarConfig(config: IToolbarConfig): void,
  updateChatInputConfig(config: IChatInput): void,
  updateSmallCopilotConfig(config: SmallCopilotConfig): void,
  addListener(cb: EventCenterObserverCallback): void,
  olaChatInsertTextAtAnchor(params: OlaChatModuleParams): void,
  olaChatBackfilllQueryParams(params: OlaChatModuleParams): void,
  getRightCopilotCurWidth(): number,
  getRightCopilotShowStatus(): boolean,
  updateIntentionText(str: string): void,
  setDisabledIntentionList(list: IDisabledIntentionItem[]): void,
  getDisabledIntentionList(): IDisabledIntentionItem[],
  setDataSourceInfo(dataSourceInfo: DataSourceInfo): void,
  setSqlModeStatus(status: boolean): void,
  getOlaChatServerHost(): string,
}

// 切换右侧copilot窗口隐藏与显示
function switchRightCopilotShowStatus(visible: boolean | undefined): void {
  useOlaChatStore()?.switchRightCopilotShow?.(visible);
}

function getRightCopilotCurWidth(): number {
  return DEFAULT_RIGHT_COPILOT_WIDTH;
}

function getRightCopilotShowStatus(): boolean {
  return useOlaChatStore()?.showRightOlaCopilot;
}

function updateIntentionText(str: string): void {
  return useOlaChatStore()?.updateIntentionText?.(str);
}

function addOlaChatQuestion(params: AddQuestionParams): void {
  useOlaChatStore()?.copilotAddQuestionCb?.(params);
}

function updateToolbarConfig(config: IToolbarConfig): void {
  useOlaChatStore()?.updateToolbarConfig?.(config);
}

function updateChatInputConfig(config: IChatInput): void {
  useOlaChatStore()?.updateChatInputConfig?.(config);
}

function updateSmallCopilotConfig(config: SmallCopilotConfig): void {
  useOlaChatStore()?.updateSmallCopilotConfig?.(config);
}

function setDisabledIntentionList(list?: IDisabledIntentionItem[]): void {
  useOlaChatStore()?.setDisabledIntentionList?.(list);
}

function getDisabledIntentionList(): IDisabledIntentionItem[] {
  return useOlaChatStore()?.disabledIntentionList || [];
}

function setDataSourceInfo(dataSourceInfo?: DataSourceInfo): void {
  useOlaChatStore()?.setDataSourceInfo?.(dataSourceInfo);
}

function setSqlModeStatus(status: boolean): void {
  useOlaChatStore()?.setSqlModeStatus?.(status);
}

function getOlaChatServerHost(): string {
  return useOlaChatStore()?.getOlaChatServerHost?.() || '';
}

function dispatchInsertTextAtAnchor(
  type: 'insertTextAtAnchor',
  params: OlaChatModuleParams,
): void {
  eventCenter.dispatch(OlaChatEventType.Action, {
    type,
    ...params,
  });
}

function dispatchBackfillQueryParams(
  type: 'backfillQueryParams',
  params: OlaChatModuleParams,
): void {
  eventCenter.dispatch(OlaChatEventType.Action, {
    type,
    ...params,
  });
}

function olaChatInsertTextAtAnchor(params: OlaChatModuleParams): void {
  dispatchInsertTextAtAnchor('insertTextAtAnchor', params);
}

function olaChatBackfilllQueryParams(params: OlaChatModuleParams): void {
  dispatchBackfillQueryParams('backfillQueryParams', params);
}

function  addListener(cb: EventCenterObserverCallback): void {
  eventCenter.on(OlaChatEventType.Action, (...args) => {
    console.log('当前激活的 App', args);
    cb(...args);
  });
}

const olaChatAPI: IOlaChatAPI = {
  switchRightCopilotShowStatus,
  addOlaChatQuestion,
  updateToolbarConfig,
  updateChatInputConfig,
  updateSmallCopilotConfig,
  addListener,
  olaChatInsertTextAtAnchor,
  olaChatBackfilllQueryParams,
  getRightCopilotCurWidth,
  getRightCopilotShowStatus,
  updateIntentionText,
  setDisabledIntentionList,
  getDisabledIntentionList,
  setDataSourceInfo,
  setSqlModeStatus,
  getOlaChatServerHost,
};

export default olaChatAPI;
