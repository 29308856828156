/**
 * @description 后端 API 集合
 * @module request
 */
import { IResponse } from '@suite/shared';
import http from '../request';
import type { PageInfo } from '@/types/apis/base';
import type {
  LocatedOrgReq,
  OrgReq,
  OrgUint,
  RecentOrgUnit,
  SearchOrgsReq,
  VisitBizReq,
  CorporationInfo,
  AppInfo,
  BusinessReq,
  BusinessInfo,
  CreateBizParams,
  RecentVisitReq,
  SwitchAppReq,
  DemoBizReq,
  UserOrgAdmin,
  OrgDetailInfo,
} from '@/types/apis/organisation';
import type { BEAppKeyType } from '@/constants/micro';

/**
 * 获取组织架构
 * @param orgReq {OrgReq}
 * @returns
 */
export const fetchOrganization = function fetchOrganization(orgReq: OrgReq): Promise<PageInfo<OrgUint[]>> {
  return http.get('/api/suiteserver/web/scopeSwitch/pageList', {
    params: orgReq,
  }).then(res => res.data);
};

/**
 * 获取最近访问的空间
 * @param corporationId
 * @param appKey {BEAppKeyType}
 * @returns
 */
export const fetchRecentBiz = function fetchRecentBiz(params: RecentVisitReq): Promise<RecentOrgUnit[]> {
  return http.get('/api/suiteserver/web/recent/queryRecentBiz', {
    params,
  }).then(res => res.data);
};

/**
 * 获取切换子应用后，子应用应该进入的空间
 * @param corporationId
 * @param appKey {BEAppKeyType}
 * @returns 返回存在三种情况：
 * 1. null，子应用不存在空间概念，只与用户有关，则无需特殊逻辑直接进入子应用
 * 2. 返回即将进入的子应用的全部层级结构（包含业务，空间内容等），则直接进入层级结构返回的空间
 * 3. 返回即将进入的子应用的全部层级结构，但是部分结构的id为空，这意味着当前用户在此子应用下没有访问记录，或者对应的子应用下没有用户访问过的空间，
 *    此时需要进入空间选择页，让用户选择需要进入的空间。
 */
export const switchApp = function switchApp(params: SwitchAppReq): Promise<RecentOrgUnit[] | null> {
  return http.get('/api/suiteserver/web/recent/switchApp', {
    params,
  }).then(res => res.data);
};

/**
 * 获取demo空间
 * @param appKey {BEAppKeyType}
 * @returns
 */
export const fetchDemoBiz = function fetchDemoBiz(params: DemoBizReq): Promise<RecentOrgUnit[]> {
  return http.get('/api/suiteserver/web/biz/findDemoBiz', {
    params,
  }).then(res => res.data);
};

/**
 * 上报最近访问的空间
 * @param visitBiz {VisitBizReq}
 * @returns
 */
export const reportVisitBiz = function reportVisitBiz(visitBiz: VisitBizReq): Promise<IResponse<void>> {
  return http.post('/api/suiteserver/web/upload/userVisitBiz', visitBiz);
};

/**
 * 定位组织架构
 * @param params {LocatedOrgReq}
 * @returns
 */
export const locatedOrg = function locatedOrg(params: LocatedOrgReq): Promise<RecentOrgUnit[]> {
  return http.get('/api/suiteserver/web/scopeSwitch/scopeLocated', {
    params,
    // 定位组织接口存在传空的情况，这里不再提示
    disabledErrorTip: true,
  }).then(res => res.data);
};

/**
 * 查询组织架构
 * @param params {SearchOrgsReq}
 * @returns
 */
export const fetchOrgsByKeywords = function fetchOrgsByKeywords(params: SearchOrgsReq): Promise<RecentOrgUnit[][]> {
  return http.get('/api/suiteserver/web/biz/pageList', {
    params,
  }).then(res => res.data.itemList);
};

/**
 * 获取用户最近进入的企业 ID
 * @returns
 */
export const fetchCurrentCorpId = function fetchCurrentCorpId(): Promise<string> {
  return http.get('/api/suiteserver/web/corporation/getLastCorporation').then(res => res.data);
};

/**
 * 获取空间下用户相关角色信息，当前空间切换时调用
 */
export const fetchBizUserAdmin = function fetchBizUserAdmin(
  appKey: BEAppKeyType,
  bizId: string,
): Promise<UserOrgAdmin> {
  return http.get('/api/suiteserver/web/user/getUserAdminInfo', {
    params: {
      appKey,
      bizId,
    },
  }).then(res => res.data);
};

/**
 * 通过企业 ID 获取企业信息
 * @returns
 */
export const fetchCurrentCorpInfo = function fetchCurrentCorpInfo(corporationId: string): Promise<CorporationInfo> {
  return http.get('/api/suiteserver/web/corporation/getCorporationInfo', {
    params: {
      corporationId,
    },
  }).then(res => res.data);
};

/**
 * 获取当前企业下应用信息
 * @param corporationId
 * @returns
 */
export const fetchAppsByCorpId = function fetchAppsByCorpId(corporationId: string): Promise<AppInfo[]> {
  return http.get('/api/suiteserver/web/corporation/getApps', {
    params: {
      corporationId,
    },
  }).then(res => res.data);
};

/**
 * 获取创建空间时必选的 AppKey，注意这里返回的 AppKey 为后端 AppKey
 * @param corporationId
 * @returns
 */
export const fetchRequiredAppKeys = function fetchRequiredAppKeys(corporationId: string): Promise<BEAppKeyType[]> {
  return http.get('/api/suiteserver/web/corporation/getRequiredAppKeys', {
    params: {
      corporationId,
    },
  }).then(res => res.data);
};

/**
 * 获取业务列表
 * @param params
 * @returns
 */
export const fetchBusinessList = function fetchBusinessList(params: BusinessReq): Promise<PageInfo<BusinessInfo[]>> {
  return http.get('/api/suiteserver/web/busi/pageListBusi', {
    params: {
      ...params,
      // 排除未归属业务，新建空间的时候需要传 true，排除
      filterNotAttributableBusi: true,
    },
  }).then(res => res.data);
};

/**
 * 获取当前业务信息
 * @param data
 * @returns
 */
export const fetchBusinessInfo = function fetchBusinessInfo(busiId: string): Promise<BusinessInfo> {
  return http.get('/api/suiteserver/web/busi/findByBusiId', { params: { busiId } }).then(res => res.data);
};

/**
 * 创建空间
 * @param data
 * @returns
 */
export const createBiz = function createBiz(data: CreateBizParams): Promise<IResponse> {
  return http.post('/api/suiteserver/web/biz/create', data);
};

/**
 * 获取租户信息，默认获取最近访问的租户信息
 * @returns
 */
export const fetchLastCorporationV2 = function fetchLastCorporationV2(): Promise<OrgDetailInfo> {
  return http.get('/api/suiteserver/web/corporation/getLastCorporationV2').then(res => res.data);
};
