/*
 * @Author: cocogong
 * @Date: 2024-05-28 17:19:09
 * @LastEditTime: 2024-08-12 19:24:07
 * @LastEditors: cocogong
 * @Description: util
 */
/**
 * @Author cocogong
 * @Date 2024-05-21 17:02:58
 * @LastEditTime 2024-05-21 20:06:22
 * @LastEditors cocogong
 * @description 通用的函数
 * @module types
 */

export function getRightCopilotWidth(): number {
  const windowWidth = window.innerWidth;
  if (windowWidth >= 1920) {
    return Math.min(windowWidth * 0.25, 480);
  }
  return Math.max(windowWidth * 0.25, 380);
}

export const DEFAULT_RIGHT_COPILOT_WIDTH = getRightCopilotWidth();

export function adjustPos(curX: number, curY: number, width: number, height: number) {
  return {
    left: (curX + width) > window.innerWidth - 10 ? (window.innerWidth - width - 10) : curX,
    isBottom: (curY + height) > window.innerHeight - 10,
    top: (curY + height) > window.innerHeight - 10 ? (window.innerHeight - height - 10) : curY,
  };
}
// 问答场景
export const OLA_CHAT_ANSWER_PRODUCT_TYPE = {
  TOOL: 2,
  SMALL: 3,
};

// olaChat提交问题的意图识别类型
export const OLA_CHAT_INTENTION_TYPE_MAP = {
  TABLE: 8,  // 找表
  METRICS: 3, // 指标计算,指标意图
  OPT_SQL: 11, // SQL优化,智能纠错
  EXPLAIN_SQL: 12, // SQL解读
  DATA_INTERPRETER: 13, // 结果解读
  SQL_QA: 15, // SQL问答, sql知识问答
  SQL: 5,   // 生成SQL
  // 文档问答
  USER_GUID: 4,
};
